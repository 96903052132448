import { Grid } from '@mui/material';
import React, { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataContact = (props) => {
    const [numPage, setNumPage] = useState(1);
    const [rowsCount, setRowsCount] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    const getDataCreditTerm = (contId, billToId = 0) => {
        ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', contId, billToId)
            .then((response) => {
                if (response.data.code === 200) {
                    props.setOutstandingInIDR(response.data.data.shipmentOrderOSInv.outstandingInvInIDR);
                    props.setOutstandingInUSD(response.data.data.shipmentOrderOSInv.outstandingInvInUSD);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error });
            })
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (props.tipe === 1) {
                props.setTipe(0);

                if (props.contactTypeId === 11) {
                    props.setAgentId(props.SelectedData.contactId);
                    props.setAgentName(props.SelectedData.contactName);
                    props.setAgentAddress(props.SelectedData.contactAddress);

                    if (props.jobType.serviceType === 'Export') {
                        props.setBLAgentId(props.SelectedData.contactId);
                        props.setBLAgentName(props.SelectedData.contactName);
                        props.setBLAgentAddress(props.SelectedData.contactAddress);

                        props.setSIAgentId(props.SelectedData.contactId);
                        props.setSIAgentName(props.SelectedData.contactName);
                        props.setSIAgentAddress(props.SelectedData.contactAddress);

                        props.setSIConsigneeId(props.SelectedData.contactId);
                        props.setSIConsigneeName(props.SelectedData.contactName);
                        props.setSIConsigneeAddress(props.SelectedData.contactAddress);

                        props.setSINPartyId(props.SelectedData.contactId);
                        props.setSINPartyName(props.SelectedData.contactName);
                        props.setSINPartyAddress(props.SelectedData.contactAddress);
                    }

                    if (props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR') {
                        props.setDeliveryId(props.SelectedData.contactId);
                        props.setDeliveryName(props.SelectedData.contactName);
                        props.setDeliveryAddress(props.SelectedData.contactAddress);

                        if (props.jobType.portType === 'SEA') {
                            props.setTranshipmentId(props.SelectedData.contactId);
                            props.setTranshipmentName(props.SelectedData.contactName);
                            props.setTranshipmentAddress(props.SelectedData.contactAddress);

                            props.setPayableAgentIdOBL(props.SelectedData.contactId);
                            props.setPayableAgentNameOBL(props.SelectedData.contactName);
                        }
                        else if (props.jobType.portType === 'AIR') {
                            props.setPayableAgentIdMAWB(props.SelectedData.contactId);
                            props.setPayableAgentNameMAWB(props.SelectedData.contactName);
                        }
                    }
                }

                else if (props.contactTypeId === 12) {
                    props.setDeliveryId(props.SelectedData.contactId);
                    props.setDeliveryName(props.SelectedData.contactName);
                    props.setDeliveryAddress(props.SelectedData.contactAddress);
                }

                else if (props.contactTypeId === 13) {
                    props.setTranshipmentId(props.SelectedData.contactId);
                    props.setTranshipmentName(props.SelectedData.contactName);
                    props.setTranshipmentAddress(props.SelectedData.contactAddress);
                }

                else if (props.contactTypeId === 21) {
                    props.setSICollectAgentId(props.SelectedData.contactId);
                    props.setSICollectAgentName(props.SelectedData.contactName);
                    props.setSICollectAgentAddress(props.SelectedData.contactAddress);
                }

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else if (props.tipe === 2) {
                props.setTipe(0);

                if (props.tipe2 !== 1) {
                    props.setBookingPartyId(props.SelectedData.contactId);
                    props.setBookingPartyName(props.SelectedData.contactName);
                    props.setBookingPartyAddress(props.SelectedData.contactAddress);
                    props.setTipe2(1);

                    emptyState();
                    props.handleClick();

                    props.setIsModal(false);
                }
                else {
                    if (props.contactTypeId === 14) {
                        props.setShipperId(props.SelectedData.contactId);
                        props.setShipperName(props.SelectedData.contactName);
                        props.setShipperAddress(props.SelectedData.contactAddress);

                        if (props.jobType.serviceType === 'Export') {
                            props.setBLShipperId(props.SelectedData.contactId);
                            props.setBLShipperName(props.SelectedData.contactName);
                            props.setBLShipperAddress(props.SelectedData.contactAddress);
                        }

                        if (props.jobType.serviceType !== 'Import') {
                            props.setCreditTermUSD(props.SelectedData.creditTermInUSD);
                            props.setCreditTermDays(props.SelectedData.creditTermInDays);
                            props.setCreditTermIDR(props.SelectedData.creditTermInIDR);

                            getDataCreditTerm(props.SelectedData.contactId);

                            if (props.SelectedData.contactId === props.billToShipperId) {
                                props.setBillToShipperId(0);
                                props.setBillShipperName(0);
                            }
                        }

                        emptyState();
                        props.handleClick();

                        props.setIsModal(false);
                    }

                    else if (props.contactTypeId === 15) {
                        if (props.SelectedData.contactId !== props.ShipperId) {
                            props.setBillToShipperId(props.SelectedData.contactId);
                            props.setBillShipperName(props.SelectedData.contactName);

                            props.setCreditTermUSD(props.SelectedData.creditTermInUSD);
                            props.setCreditTermDays(props.SelectedData.creditTermInDays);
                            props.setCreditTermIDR(props.SelectedData.creditTermInIDR);

                            getDataCreditTerm(props.ShipperId, props.SelectedData.contactId);

                            emptyState();
                            props.handleClick();

                            props.setIsModal(false);
                        }
                        else {
                            showToast({ type: 'error', message: 'Data Has Been Selected As Shipper!' });

                            emptyState();
                            props.handleClick();

                            props.setIsModal(false);
                        }
                    }

                    else if (props.contactTypeId === 20) {
                        props.setBookingPartyId(props.SelectedData.contactId);
                        props.setBookingPartyName(props.SelectedData.contactName);
                        props.setBookingPartyAddress(props.SelectedData.contactAddress);

                        emptyState();
                        props.handleClick();

                        props.setIsModal(false);
                    }
                }
            }
            else if (props.tipe === 3) {
                props.setTipe(0);

                if (props.contactTypeId === 16) {
                    props.setConsigneeId(props.SelectedData.contactId);
                    props.setConsigneeName(props.SelectedData.contactName);
                    props.setConsigneeAddress(props.SelectedData.contactAddress);

                    props.setTempConsigneeId(props.SelectedData.contactId);
                    props.setTempConsigneeName(props.SelectedData.contactName);
                    props.setTempConsigneeAddress(props.SelectedData.contactAddress);

                    if (props.jobType.serviceType === 'Export') {
                        props.setBLConsigneeId(props.SelectedData.contactId);
                        props.setBLConsigneeName(props.SelectedData.contactName);
                        props.setBLConsigneeAddress(props.SelectedData.contactAddress);
                    }

                    if (props.jobType.serviceType === 'Import') {
                        props.setCreditTermUSD(props.SelectedData.creditTermInUSD);
                        props.setCreditTermDays(props.SelectedData.creditTermInDays);
                        props.setCreditTermIDR(props.SelectedData.creditTermInIDR);

                        getDataCreditTerm(props.SelectedData.contactId);

                        if (props.SelectedData.contactId === props.billToConsigneeId) {
                            props.setBillToConsigneeId(0);
                            props.setBillConsigneeName(0);
                        }
                    }

                    props.setNPartyId(props.SelectedData.contactId);
                    props.setNPartyName(props.SelectedData.contactName);
                    props.setNPartyAddress(props.SelectedData.contactAddress);

                    if (props.jobType.serviceType === 'Export') {
                        props.setBLNPartyId(props.SelectedData.contactId);
                        props.setBLNPartyName(props.SelectedData.contactName);
                        props.setBLNPartyAddress(props.SelectedData.contactAddress);
                    }

                    if (props.jobType.portType !== 'AIR') {
                        props.setPayableConsigneeIdHBL(props.SelectedData.contactId);
                        props.setPayableConsigneeNameHBL(props.SelectedData.contactName);
                    }
                    else {
                        props.setPayableConsigneeIdHAWB(props.SelectedData.contactId);
                        props.setPayableConsigneeNameHAWB(props.SelectedData.contactName);
                    }

                    emptyState();
                    props.handleClick();

                    props.setIsModal(false);
                }

                else if (props.contactTypeId === 17) {
                    props.setNPartyId(props.SelectedData.contactId);
                    props.setNPartyName(props.SelectedData.contactName);
                    props.setNPartyAddress(props.SelectedData.contactAddress);

                    if (props.jobType.serviceType === 'Export') {
                        props.setBLNPartyId(props.SelectedData.contactId);
                        props.setBLNPartyName(props.SelectedData.contactName);
                        props.setBLNPartyAddress(props.SelectedData.contactAddress);
                    }

                    emptyState();
                    props.handleClick();

                    props.setIsModal(false);
                }

                else if (props.contactTypeId === 19) {
                    if (props.SelectedData.contactId !== props.ConsigneeId) {
                        props.setBillToConsigneeId(props.SelectedData.contactId);
                        props.setBillConsigneeName(props.SelectedData.contactName);

                        props.setCreditTermUSD(props.SelectedData.creditTermInUSD);
                        props.setCreditTermDays(props.SelectedData.creditTermInDays);
                        props.setCreditTermIDR(props.SelectedData.creditTermInIDR);

                        getDataCreditTerm(props.ConsigneeId, props.SelectedData.contactId);

                        emptyState();
                        props.handleClick();

                        props.setIsModal(false);
                    }
                    else {
                        showToast({ type: 'error', message: 'Data Has Been Selected As Consignee!' });
                        emptyState();
                        props.handleClick();

                        props.setIsModal(false);
                    }
                }
            }
            else if (props.tipe === 4) {
                props.setTipe(0);

                props.setSSLineId(props.SelectedData.contactId);
                props.setSSLineName(props.SelectedData.contactName);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else if (props.tipe === 5) {
                props.setTipe(0);

                props.setIATAId(props.SelectedData.contactId);
                props.setIATAName(props.SelectedData.contactName);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else if (props.tipe === 6) {
                props.setTipe(0);

                if (props.jobType.portType === 'SEA') {
                    props.setEMKLId(props.SelectedData.contactId);
                    props.setEMKLName(props.SelectedData.contactName);
                }
                else {
                    props.setBrokerId(props.SelectedData.contactId);
                    props.setBrokerName(props.SelectedData.contactName);
                }

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else if (props.tipe === 7) {
                props.setTipe(0);

                props.setDepoId(props.SelectedData.contactId);
                props.setDepoName(props.SelectedData.contactName);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataContact;