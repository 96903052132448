import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';
import { defaultDate } from '../../../../../utils/date';

const ModalBank = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        if (props.isAccount > 1) {
            props.setAccountCode('');
            props.setAccountName('');
        }

        props.setCheckBG('');
        props.setRemarks('');
        props.setAmountCrr(1);
        props.setAmount(0);
        props.setDueDate('');
        props.setIsDueDate(false);
        props.setStatusDueDate(false);
    };

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const handleSave = () => {
        let errMessage = undefined;

        if (!props.AccountCode) {
            errMessage = 'Please Select Chart of Account!';
        }
        else if (props.Amount <= 0) {
            errMessage = 'Please Input Amount!';
        }
        else if (props.CheckBG === '') {
            errMessage = 'Please Input Check BG!';
        }

        if (errMessage !== undefined) {
            showToast({ type: 'error', message: errMessage });
        }
        else {
            let sequence = 1;
            let pvId = 0;
            let dueDate = defaultDate;

            if (props.detailEdit) {
                sequence = props.selectedDetail.sequence;
            }
            else {
                if (props.detailSequence > 0) sequence = props.detailSequence + 1;
            }

            if (props.paymentVoucherId !== undefined || props.paymentVoucherId !== null) {
                pvId = props.paymentVoucherId;

                if (pvId === undefined) {
                    pvId = 0;
                }
            }
            else {
                pvId = 0;
            }

            if (props.DueDate !== '') {
                dueDate = props.DueDate;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "pvId": pvId,
                "pvDetailId": props.selectedPaymentVoucher.id,
                "sequence": sequence,
                "paymentBy": props.PaymentBy,
                "accountCode": props.AccountCode,
                "accountName": props.AccountName.replace(/\s+$/g, ''),
                "checkBG": props.CheckBG,
                "remarks": props.Remarks,
                "amountCrr": parseFloat(props.AmountCrr),
                "amount": formatCurrency === 'USD' ? parseFloat(convertMask(props.Amount)) : parseFloat(convertMaskIDR(props.Amount)),
                "dueDate": dueDate,
                "statusDueDate": props.statusDueDate,

                "idLama": 0,
            }

            if (!props.detailEdit) {
                props.setBankData([...props.BankData, data]);

                let tempDate = '1785-12-06T10:51:29.426Z';
                const newArray = props.DataPaymentDetail.slice();
                newArray.forEach(el => {
                    if (el.sequence === props.selectedPaymentVoucher.sequence) {
                        el.paymentVoucherDetailBanks = [...props.BankData, data];
                        if (el.sequence === props.TempSeq) {
                            if (props.TempIsPPh23 === true) {
                                el.isPPh23 = true;
                                props.setTempSeq(0);
                                props.setTempIsPPh23(false);
                            }
                        }
                    }

                    if (el.paymentVoucherDetailBanks.length > 0) {
                        el.paymentVoucherDetailBanks.forEach(element => {
                            if (new Date(tempDate) < new Date(element.dueDate)) {
                                tempDate = element.dueDate;
                            }
                        });
                    }
                });
                props.setDueDateLast(tempDate);
                props.setDataPaymentDetail(newArray);

                props.calculateTotalDebitCredit(newArray, props.Rate, props.PaymentBy, props.PPh23AmountReal);
            }
            else {
                const newArr = props.BankData.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.accountCode = data.accountCode;
                        el.accountName = data.accountName;
                        el.checkBG = data.checkBG;
                        el.remarks = data.remarks;
                        el.amountCrr = data.amountCrr;
                        el.amount = data.amount;
                        el.dueDate = data.dueDate;
                    }
                })
                props.setBankData(newArr);

                const newArray = props.DataPaymentDetail.slice();
                let tempDate = '1785-12-06T10:51:29.426Z';
                newArray.forEach(el => {
                    if (el.sequence === props.selectedPaymentVoucher.sequence) {
                        el.paymentVoucherDetailBanks = newArr;
                    }

                    if (el.paymentVoucherDetailBanks.length > 0) {
                        el.paymentVoucherDetailBanks.forEach(element => {
                            if (new Date(tempDate) < new Date(element.dueDate)) {
                                tempDate = element.dueDate;
                            }
                        });
                    }
                });
                props.setDueDateLast(tempDate);
                props.setDataPaymentDetail(newArray);

                props.calculateTotalDebitCredit(newArray, props.Rate, props.PaymentBy, props.PPh23AmountReal);
            }

            props.setIsAddValue(true);
            props.setIsInput(false);

            emptyState();
            handleClose();
            props.setDetailEdit(false);
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'}>
                        <Input
                            label={'Account Code'}
                            value={props.AccountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '50%',
                                }
                            }}
                            readOnly
                        />

                        <Input
                            value={props.AccountName}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important'
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => { searchAccount('delete'); props.setIsAccount(props.isAccount + 1); }}
                                            disabled={props.isApprovedAcc}
                                            hidden={props.AccountName && !props.isPosted ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            disabled={props.isApprovedAcc}
                                            hidden={props.isPosted}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Check BG"
                        value={props.CheckBG}
                        onChange={(e) => props.setCheckBG(e.target.value)}
                        disabled={props.printing > 0}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                label={'Remarks'}
                value={props.Remarks}
                onChange={(e) => props.setRemarks(e.target.value)}
                disabled={props.printing > 0}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.AmountCrr}
                        onChange={(e) => props.setAmountCrr(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        disabled
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Amount"
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        onChange={(e) => props.setAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                        disabled={props.printing > 0}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <CustomDatePicker
                        label='Due Date'
                        type={'date'}
                        value={props.DueDate}
                        disabled={props.printing > 0}
                        onChange={props.setDueDate}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.isPosted)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        isAccount={props.isAccount}
                        setIsAccount={props.setIsAccount}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalBank;