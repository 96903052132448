import React, { useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'

import ApiService from '../../../../services/ApiService'

import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner'
import TableX from '../../../../components/Tables/TableX'
import { Paginate, showToast } from '../../../../components/ui'

const TableReport = ({ shipmentData, title, params, tableData, module, target }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [dataTable, setDataTable] = useState(tableData?.data || [])
  const [columnData, setColumnData] = useState(tableData?.columns || [])
  const [selectedData, setSelectedData] = useState({});

  // Filter
  const [goTo, setGoTo] = useState(1)
  const [stateX, setStateX] = useState({})
  const [filter, setFilter] = useState([])
  const [numPage, setNumPage] = useState(1)
  const [sortArr, setSortArr] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [rowsCount, setRowsCount] = useState(50)
  const [totalRows, setTotalRows] = useState(tableData?.totalRows || 50)

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getPayloadParams = (withOrder, withInv, withJob) => {
    let params = []
    if (withOrder) {
      params.push({ "attr": "shipmentOrderId", "value": String(shipmentData?.id) })
    } else {
      params.push({ "attr": "shipmentId", "value": String(shipmentData?.id) })
    }

    if (withInv) params.push({ "attr": "IsWithInv", "value": "true" })
    if (withJob) params.push({ "attr": "IsWithJob", "value": 'true' })

    return params
  }

  const getParamsByTarget = () => {
    let params = getPayloadParams()
    switch (target) {
      case 'tr':
        params = getPayloadParams(true, false, true)
        break;

      case 'or':
        params = getPayloadParams(false, true)
        break;

      case 'pv':
        params = getPayloadParams(true)
        break;

      default:
        break;
    }

    return params
  }

  const getData = async (pageNumber, pageSize, filter) => {
    setIsLoading(true)
    try {
      let res;
      if (module === 'Finance') {
        res = await ApiService.FinAccPostByPage(params, 'SEARCH', pageNumber, pageSize, getParamsByTarget(), filter)
      } else {
        res = await ApiService.OperationDynamicPostByPage(params, 'SEARCH', pageNumber, pageSize, getParamsByTarget(), filter)
      }

      if (res?.data?.code === 200) {
        let key;
        switch (target) {
          case 'inv': key = 'invoices'
            break;
          case 'or': key = 'officialReceipt'
            break;
          case 'pr': key = 'paymentRequests'
            break;
          case 'pv': key = 'paymentVoucher'
            break;
          case 'tr': key = 'temporaryReceipts'
            break;
          default:
            break;
        }

        const data = res.data.data[key]
        setDataTable(data)
        setColumnData(res.data.data.columns.headerColumns)
        setTotalRows(res.data.totalRowCount)
      } else {
        errorToast(title)
      }
    } catch (error) {
      console.log(error)
      errorToast(title)
    }
    setIsLoading(false)
  }

  const resetData = () => {
    setGoTo(1);
    setNumPage(1);
    setRowsCount(25);
    setTotalRows(25);
    setFilter([]);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
    setSelectedData({})

    getData(1, 50)
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter, sortArr);
  }

  return (
    <Stack spacing={1} sx={{ borderRadius: '12px', padding: '12px 6px', border: '1px solid var(--Disable-Grey-Text-1, #A5ACBE)' }}>
      <Typography
        sx={{
          color: 'var(--Primary-One, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '19.6px',
        }}
      >
        {title}
      </Typography>

      <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
        {
          ButtonComponent.RefreshButton(
            sortArr,
            rowsCount,
            columnData,
            setGoTo,
            resetData,
            setFilterBy,
            filterBy,
            setFilter,
            setNumPage,
            setRowsCount,
            getData,
            stateX,
            setStateX,
            false
          )
        }

        <div
          className="rounded-10 table-responsive"
          style={{
            maxHeight: '300px',
            marginTop: '16px',
            padding: '2px 16px',
            borderRadius: '12px',
            background: '#F2F2F2'
          }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <TableX
                uniqueKey={'id'}
                goTo={goTo}
                rowsCount={rowsCount}
                setNumPage={setNumPage}
                setRowsCount={setRowsCount}
                getData={getData}
                columnData={columnData}
                dataMap={dataTable}
                SelectedData={selectedData}
                setSelectedData={setSelectedData}
                setFilter={setFilter}
                setFilterBy={setFilterBy}
                CellDoubleClick={() => undefined}
                stateX={stateX}
                setStateX={setStateX}
                sortArr={sortArr}
                setSortArr={setSortArr}
                setGoTo={setGoTo}
              />
            </>
          )}
        </div>

        <div style={{ marginTop: '16px' }}>
          <Paginate
            page={numPage}
            size='small'
            limit={rowsCount}
            totalData={totalRows}
            onChange={onChangePaging}
            onChangeLimit={onChangeLimit}
          />
        </div>
      </Grid>
    </Stack>
  )
}

export default TableReport