import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, Box, Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../../services/ApiService';

import TableX from '../../../components/Tables/TableX';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import CreateEditRegUserEntity from './CreateEditRegUserEntity';
import ButtonRegUserEntity from './Buttons/ButtonRegUserEntity';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';

const RegUserEntityList = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const roleId = JSON.parse(localStorage.getItem("roleId"))
  const companyId = JSON.parse(localStorage.getItem("companyId"))
  const branchId = JSON.parse(localStorage.getItem("branchId"))

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);

  const [isModal, setIsModal] = useState(false);

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Reg User Entity', link: '/Majura/RegUserEntity', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  let countRefresh = 0;

  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  useEffect(() => {
    if (countRefresh === 0) {
      document.title = 'Reg User Entity - ' + JSON.parse(localStorage.getItem('branchName'))
      ApiService.refreshToken();
      countRefresh++;

      getDataAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'IRUE')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  };

  const getData = async (pageNumber = 1, pageSize = 50, filters = []) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    setGoTo(pageNumber)

    try {
      const params = []
      if (roleId === 2) {
        params.push({ attr: 'companyId', value: String(companyId) })
      } else if (roleId === 6) {
        params.push({ attr: 'companyId', value: String(companyId) })
        params.push({ attr: 'branchId', value: String(branchId) })
      }

      const res = await ApiService.IdentityPostByPage('regUserEntity/regUserEntity', 'PAGE', pageNumber, pageSize, params, filters)
      if (res.data.code === 200) {
        setSelectedData({})

        let tempData = res.data.data.userEntity
        let indexedData = tempData.map((el, index) => {
          return { ...el, index }
        })

        setDataMap(indexedData)
        setColumnData(res.data.data.columns)
        setTotalRows(res.data.totalRowCount)
        setIsLoadingBackDrop(false)
      } else {
        setIsLoadingBackDrop(false)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + res.data.message })
        emptyTable()
      }

      setIsLoading(false)

    } catch (error) {
      console.log(error)
      setIsLoadingBackDrop(false)
      showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
    }
  }

  const emptyTable = () => {
    setDataMap([])
    setColumnData([])
    setTotalRows(50)
  }

  const handleReset = () => {
    emptyState();
    getData();
  };

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setGoTo(1);
    setSortArr([]);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setStateX({});
  };

  const reloadData = () => {
    showToast({ type: 'info', message: 'Reloading Data' })
    getData(numPage, rowsCount)
  };

  const toggleModal = () => {
    setIsModal(!isModal)
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter, sortArr);
  }

  const ButtonComponents = () => {
    return (
      <ButtonRegUserEntity
        handleAdd={toggleModal}
        reloadData={reloadData}
        dataRegUserAccess={dataRegUserAccess}
      />
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo btnPageRef={btnPageRef}
            title={'Reg User Entity'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div ref={btnTableRef}>
              {ButtonComponent.RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy,
                filterBy, setFilter, setNumPage, setRowsCount, getData, stateX, setStateX)}
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableX
                        uniqueKey={'id'}
                        goTo={goTo}
                        rowsCount={rowsCount}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        setFilter={setFilter}
                        setFilterBy={setFilterBy}
                        CellDoubleClick={() => undefined}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                        setGoTo={setGoTo}
                        static={true}
                        preventKeyCapture={true}
                      />
                    </div>
                  </>
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>

        {isModal && (
          <CreateEditRegUserEntity
            ref={ref}
            isModal={isModal}
            closeModal={toggleModal}
            fetchData={getData}
          />
        )}

        {
          isLoadingBackDrop && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadingBackDrop}
            >
              <LoadingSpinner />
            </Backdrop>
          )
        }
      </Box>
    </>
  )
})

export default RegUserEntityList
