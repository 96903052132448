import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ApiService from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';

const SelectDataShipmentOrder = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const paramRegContact = "regContact/regContact"

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(5);
        props.setTotalRows(5);
        setFilter([]);
        setFilterBy([]);
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            props.setId(props.SelectedData.id);
            props.setName(props.SelectedData.shipmentNo);
            const filteredArr = props.jobTypeList.filter(item => item.code === props.SelectedData.jobTypeCode)[0]
            if (filteredArr.serviceType === 'Export') {
                props.setShipperConsigneeId(props.SelectedData.shipperId)
                props.setShipperConsigneeName(props.SelectedData.shipperName)
                ApiService.PostByPage(paramRegContact, 1, 10, [{
                    "field": "contactId",
                    "data": props.SelectedData.shipperId.toString()
                }])
                    .then((res) => {
                        if (res.data.code === 200) {
                            if (res.data.data.contact.length > 0) {
                                props.setWarehouseId(res.data.data.contact[0].warehousesId);
                                props.setWarehouseName(res.data.data.contact[0].warehousesName);
                                props.setSiteId(res.data.data.contact[0].sitesId);
                                props.setSiteName(res.data.data.contact[0].sitesName);
                            }
                        } else {
                            showToast({ type: 'error', message: res.error });
                        }
                    })
                    .catch((err) => {
                        showToast({ type: 'error', message: err.toString() });
                    })
            } else {
                props.setShipperConsigneeId(props.SelectedData.consigneeId)
                props.setShipperConsigneeName(props.SelectedData.consigneeName)
                ApiService.PostByPage(paramRegContact, 1, 10, [{
                    "field": "contactId",
                    "data": props.SelectedData.consigneeId.toString()
                }])
                    .then((res) => {
                        if (res.data.code === 200) {
                            if (res.data.data.contact.length > 0) {
                                props.setWarehouseId(res.data.data.contact[0].warehousesId);
                                props.setWarehouseName(res.data.data.contact[0].warehousesName);
                                props.setSiteId(res.data.data.contact[0].sitesId);
                                props.setSiteName(res.data.data.contact[0].sitesName)
                            }
                        } else {
                            showToast({ type: 'error', message: res.error });
                        }
                    })
                    .catch((err) => {
                        showToast({ type: 'error', message: err.toString() });
                    })
            }
            emptyState();
            props.handleClick();

            props.setIsModal(false);
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    function CellDoubleClick(row) {
        Select();
    }

    function handleReset() {

    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid container item direction="row">
                <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                    <div style={{ width: '25%' }}>
                        {props.SelectShipmentOrder}
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '24px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'warehouse'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataShipmentOrder;