import { Grid, InputAdornment, IconButton, MenuItem } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService from '../../../../../services/ApiService';
import SelectDataMaster from '../../../../Master/MasterWarehouse/SelectDataMaster'
import SelectDataShipmentOrder from '../Selects/SelectDataShipmentOrder';
import { useEffect } from 'react';
import { Icon, Input, Selection, showToast } from '../../../../../components/ui';

const OutBoundHeader = (props) => {
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";
    const paramSite = "warehouse/Sites/Sites";
    const paramWarehouse = "warehouse/RegSitesWarehouses/RegSitesWarehouses";
    const paramShipmentOrder = "shipmentOrder/shipmentOrder";
    const paramRegJobType = "regJobType/regJobType"
    const paramContact = "jobOwner/jobOwner"
    const paramVehicleType = "jenisKendaraan/jenisKendaraan"

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(5);

    const [titleModalSite, setTitleModalSite] = useState('');
    const [isModalSite, setIsModalSite] = useState(false);
    const [isLoadingModalSite, setIsLoadingModalSite] = useState(false);
    const [dataModalSite, setDataModalSite] = useState([]);
    const [dataMapModalSite, setDataMapModalSite] = useState([]);
    const [columnDataModalSite, setColumnDataModalSite] = useState([]);
    const [SelectedDataModalSite, setSelectedDataModalSite] = useState({});
    const [totalRowsModalSite, setTotalRowsModalSite] = useState(5);

    const [titleModalWarehouse, setTitleModalWarehouse] = useState('');
    const [isModalWarehouse, setIsModalWarehouse] = useState(false);
    const [isLoadingModalWarehouse, setIsLoadingModalWarehouse] = useState(false);
    const [dataModalWarehouse, setDataModalWarehouse] = useState([]);
    const [dataMapModalWarehouse, setDataMapModalWarehouse] = useState([]);
    const [columnDataModalWarehouse, setColumnDataModalWarehouse] = useState([]);
    const [SelectedDataModalWarehouse, setSelectedDataModalWarehouse] = useState({});
    const [totalRowsModalWarehouse, setTotalRowsModalWarehouse] = useState(5);

    const [titleModalShipmentOrder, setTitleModalShipmentOrder] = useState('');
    const [isModalShipmentOrder, setIsModalShipmentOrder] = useState(false);
    const [isLoadingModalShipmentOrder, setIsLoadingModalShipmentOrder] = useState(false);
    const [dataModalShipmentOrder, setDataModalShipmentOrder] = useState([]);
    const [dataMapModalShipmentOrder, setDataMapModalShipmentOrder] = useState([]);
    const [columnDataModalShipmentOrder, setColumnDataModalShipmentOrder] = useState([]);
    const [SelectedDataModalShipmentOrder, setSelectedDataModalShipmentOrder] = useState({});
    const [totalRowsModalShipmentOrder, setTotalRowsModalShipmentOrder] = useState(5);

    const [titleModalContact, setTitleModalContact] = useState('');
    const [isModalContact, setIsModalContact] = useState(false);
    const [isLoadingModalContact, setIsLoadingModalContact] = useState(false);
    const [dataModalContact, setDataModalContact] = useState([]);
    const [dataMapModalContact, setDataMapModalContact] = useState([]);
    const [columnDataModalContact, setColumnDataModalContact] = useState([]);
    const [SelectedDataModalContact, setSelectedDataModalContact] = useState({});
    const [totalRowsModalContact, setTotalRowsModalContact] = useState(5);

    const [titleModalVehicleType, setTitleModalVehicleType] = useState('');
    const [isModalVehicleType, setIsModalVehicleType] = useState(false);
    const [isLoadingModalVehicleType, setIsLoadingModalVehicleType] = useState(false);
    const [dataModalVehicleType, setDataModalVehicleType] = useState([]);
    const [dataMapModalVehicleType, setDataMapModalVehicleType] = useState([]);
    const [columnDataModalVehicleType, setColumnDataModalVehicleType] = useState([]);
    const [SelectedDataModalVehicleType, setSelectedDataModalVehicleType] = useState({});
    const [totalRowsModalVehicleType, setTotalRowsModalVehicleType] = useState(5);

    const [jobTypeList, setJobTypeList] = useState([])
    const [jobTypeCode, setJobTypeCode] = useState('')

    const SelectShipmentOrder = (
        <Selection
            id='0'
            label={'Job Type'}
            fullWidth={true}
            placeholder={'Select Job Type'}
            value={jobTypeCode}
            onChange={(e) => setJobTypeCode(e.target.value)}
        >
            {
                jobTypeList.map((data, index) => {
                    return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                })
            }
        </Selection>
    )

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataProductCategory(1, 5);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickSite = () => {
        if (isModalSite === false) {
            setIsModalSite(true);
            getDataSite(1, 5);
        }
        else {
            setIsModalSite(false);
        }
    };

    const handleClickWarehouse = () => {
        if (isModalWarehouse === false) {
            setIsModalWarehouse(true);
            getDataWarehouse(1, 5);
        }
        else {
            setIsModalWarehouse(false);
        }
    };

    const handleClickShipmentOrder = () => {
        if (isModalShipmentOrder === false) {
            setIsModalShipmentOrder(true);
            getDataShipmentOrder(1, 5);
        }
        else {
            setIsModalShipmentOrder(false);
        }
    };

    const handleClickContact = () => {
        if (isModalContact === false) {
            setIsModalContact(true);
            getDataContact(1, 5);
        }
        else {
            setIsModalContact(false);
        }
    };

    const handleClickVehicleType = () => {
        if (isModalVehicleType === false) {
            setIsModalVehicleType(true);
            getDataVehicleType(1, 5);
        }
        else {
            setIsModalVehicleType(false);
        }
    };

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.productCategory);

                    let temp = response.data.data.productCategory
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataSite = (pageNumber, pageSize, filters) => {
        setIsLoadingModalSite(true);
        ApiService.PostByPage(paramSite, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalSite({})
                    setDataModalSite(response.data.data.sites);

                    let temp = response.data.data.sites
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalSite(indexed)
                    setColumnDataModalSite(response.data.data.columns)
                    setTotalRowsModalSite(response.data.totalRowCount)

                    setIsLoadingModalSite(false)
                }
                setIsLoadingModalSite(false)
            })
            .catch(function (error) {
                setIsLoadingModalSite(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataWarehouse = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouse(true);
        filters = [{
            "field": "sitesId",
            "data": props.siteId.toString()
        }]
        ApiService.PostByPage(paramWarehouse, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouse({})
                    setDataModalWarehouse(response.data.data.sitesWarehouses);
                    let temp = response.data.data.sitesWarehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouse(indexed)
                    setColumnDataModalWarehouse(response.data.data.columns)
                    setTotalRowsModalWarehouse(response.data.totalRowCount)

                    setIsLoadingModalWarehouse(false)
                }
                setIsLoadingModalWarehouse(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouse(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };


    const getDataShipmentOrder = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingModalShipmentOrder(true);
        if (filters) {
            filters = [...filters, { "field": "jobTypeCode", "data": jobTypeCode }]
        } else {
            filters = [{ "field": "jobTypeCode", "data": jobTypeCode }]
        }
        ApiService.OperationPostByPage(paramShipmentOrder, pageNumber, pageSize, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalShipmentOrder({})
                    setDataModalShipmentOrder(response.data.data.shipmentOrder);

                    let temp = response.data.data.shipmentOrder
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })
                    const filteredColumn = response.data.data.columns.headerColumns.filter(item => {
                        return item.column === 'shipmentNo' || item.column === 'bookingNo' || item.column === 'shipperName' || item.column === 'agentName' || item.column === 'consigneeName'
                    })
                    setDataMapModalShipmentOrder(indexed)
                    setColumnDataModalShipmentOrder(filteredColumn)
                    setTotalRowsModalShipmentOrder(response.data.totalRowCount)

                    setIsLoadingModalShipmentOrder(false)
                }
                setIsLoadingModalShipmentOrder(false)
            })
            .catch(function (error) {
                setIsLoadingModalShipmentOrder(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingModalContact(true);
        ApiService.PostByPage(paramContact, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalContact({})
                    setDataModalContact(response.data.data.jobOwner);

                    let temp = response.data.data.jobOwner
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalContact(indexed)
                    setColumnDataModalContact(response.data.data.columns)
                    setTotalRowsModalContact(response.data.totalRowCount)

                    setIsLoadingModalContact(false)
                }
                setIsLoadingModalContact(false)
            })
            .catch(function (error) {
                setIsLoadingModalContact(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataVehicleType = (pageNumber, pageSize, filters) => {
        setIsLoadingModalVehicleType(true);
        ApiService.PostByPage(paramVehicleType, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalVehicleType({})
                    setDataModalVehicleType(response.data.data.jenisKendaraan);

                    let temp = response.data.data.jenisKendaraan
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalVehicleType(indexed)
                    setColumnDataModalVehicleType(response.data.data.columns)
                    setTotalRowsModalVehicleType(response.data.totalRowCount)

                    setIsLoadingModalVehicleType(false)
                }
                setIsLoadingModalVehicleType(false)
            })
            .catch(function (error) {
                setIsLoadingModalVehicleType(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        handleClick();
    }

    const searchSite = () => {
        setTitleModalSite("Site");
        handleClickSite();
    }

    const searchWarehouse = () => {
        if (!props.siteId) {
            showToast({ type: 'error', message: "Select Site!" })
            return
        }
        setTitleModalWarehouse("Warehouse")
        handleClickWarehouse();
    }

    const searchShipmentOrder = () => {
        ApiService.PostByPage(paramRegJobType, 1, 100, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setJobTypeList(response.data.data.jobType)
                    if (jobTypeCode === '') {
                        setJobTypeCode(response.data.data.jobType[0].code)
                    }
                    setTitleModalShipmentOrder("Job/Shipment Order")
                    handleClickShipmentOrder();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    const searchContact = () => {
        setTitleModalContact("Customer of Principal");
        handleClickContact();
    }

    const searchVehicleType = () => {
        setTitleModalVehicleType("Vehicle Type");
        handleClickVehicleType();
    }

    const selectSite = (selectedData) => {
        props.setSiteId(selectedData.id);
        props.setSiteName(selectedData.name);
        props.setWarehouseId('');
        props.setWarehouseName('');
    }

    const selectWarehouse = (selectedData) => {
        props.setWarehouseId(selectedData.warehousesId);
        props.setWarehouseName(selectedData.warehousesName);
    }

    const selectProductCategory = (selectedData) => {
        props.setIsLoadingBackDrop(true);
        if (selectedData.id !== props.productCategoryId) {
            props.setOutBoundDetailList([]);
        }
        props.setProductCategoryId(selectedData.id);
        props.setProductCategoryName(selectedData.name);
        props.setSiteId(selectedData.sitesId);
        props.setSiteName(selectedData.sitesName);
        props.setWarehouseId(selectedData.warehousesId);
        props.setWarehouseName(selectedData.warehousesName);
        ApiService.DynamicPostByPage(paramWarehouseLocation, 'PAGE', 1, 100, [
            {
                "attr": "warehouseId",
                "value": selectedData.warehousesId.toString(),
                "mathSign": "="
            },
            {
                "attr": "locationX",
                "value": selectedData.warehouseLocationX
            },
            {
                "attr": "locationY",
                "value": selectedData.warehouseLocationY
            },
            {
                "attr": "locationZ",
                "value": selectedData.warehouseLocationZ
            },
        ], [])
            .then((res) => {
                const retData = res.data.data.warehouseLocation;
                props.setWarehouseLocationId(retData[0].id);
                props.setWarehouseLocationName(retData[0].name);
                props.setIsLoadingBackDrop(false);
            })
    }

    const selectContact = (selectedData) => {
        props.setContactId(selectedData.id);
        props.setContactName(selectedData.fullName);
    }

    useEffect(() => {
        getDataShipmentOrder(1, 5)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobTypeCode])

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="OutBound Date"
                                    type={'date'}
                                    value={props.OutBoundDate}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="OutBound No"
                                    value={props.OutBoundNo}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Job/Shipment Order"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.shipmentOrderNo}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.shipmentOrderNo && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setShipmentOrderNo('');
                                                                    props.setShipmentOrderId(0);
                                                                    props.setShipperConsigneeId(0);
                                                                    props.setShipperConsigneeName('')
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchShipmentOrder()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Shipper/Consignee"
                                    value={props.shipperConsigneeName}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Product Category"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.productCategoryName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.productCategoryName && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setSiteId(0);
                                                                    props.setSiteName('');
                                                                    props.setWarehouseId(0);
                                                                    props.setWarehouseName('');
                                                                    props.setProductCategoryId(0);
                                                                    props.setOutBoundDetailList([]);
                                                                    props.setWarehouseLocationId(0);
                                                                    props.setProductCategoryName('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchProductCategory()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Site"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.siteName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.siteName && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setSiteName('');
                                                                    props.setSiteId(0)
                                                                    props.setWarehouseId(0);
                                                                    props.setWarehouseName('');
                                                                    props.setWarehouseLocationId(0);
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchSite()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Warehouse"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.warehouseName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.warehouseName && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setWarehouseId(0);
                                                                    props.setWarehouseName('');
                                                                    props.setWarehouseLocationId(0);
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchWarehouse()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Warehouse Location"
                                    value={props.warehouseLocationName}
                                    onChange={(e) => props.setWarehouseLocationName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Customer of Principal"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.contactName}
                                    onChange={(e) => props.setContactName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.contactName && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setContactId(0);
                                                                    props.setContactName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchContact()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Vehicle Type"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.vehicleTypeName}
                                    onChange={(e) => props.setVehicleTypeName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.vehicleTypeName && (
                                                        <IconButton
                                                            hidden={props.printing > 0}
                                                            onClick={
                                                                () => {
                                                                    props.setVehicleTypeId(0);
                                                                    props.setVehicleTypeName('');
                                                                    props.setVehicleNo('');
                                                                    props.setDriverName('');
                                                                    props.setDriverNo('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.printing > 0} onClick={() => searchVehicleType()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Vehicle No"
                                    value={props.vehicleNo}
                                    onChange={(e) => props.setVehicleNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Driver Name"
                                    value={props.driverName}
                                    onChange={(e) => props.setDriverName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={props.printing > 0}
                                />

                                <Input
                                    label="Driver Mobile No"
                                    value={props.driverNo}
                                    onChange={(e) => props.setDriverNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={props.printing > 0}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}

                        selectHandler={selectProductCategory}
                    />
                )
            }

            {
                isModalSite && (
                    <SelectDataMaster
                        isModal={isModalSite}
                        handleClick={handleClickSite}
                        titleModal={titleModalSite}
                        setIsModal={setIsModalSite}

                        getData={getDataSite}
                        isLoading={isLoadingModalSite}
                        totalRows={totalRowsModalSite}
                        setTotalRows={setTotalRowsModalSite}
                        SelectedData={SelectedDataModalSite}
                        setSelectedData={setSelectedDataModalSite}
                        data={dataModalSite}
                        columnData={columnDataModalSite}
                        dataMap={dataMapModalSite}

                        selectHandler={selectSite}
                    />
                )
            }

            {
                isModalWarehouse && (
                    <SelectDataMaster
                        isModal={isModalWarehouse}
                        handleClick={handleClickWarehouse}
                        titleModal={titleModalWarehouse}
                        setIsModal={setIsModalWarehouse}

                        getData={getDataWarehouse}
                        isLoading={isLoadingModalWarehouse}
                        totalRows={totalRowsModalWarehouse}
                        setTotalRows={setTotalRowsModalWarehouse}
                        SelectedData={SelectedDataModalWarehouse}
                        setSelectedData={setSelectedDataModalWarehouse}
                        data={dataModalWarehouse}
                        columnData={columnDataModalWarehouse}
                        dataMap={dataMapModalWarehouse}

                        selectHandler={selectWarehouse}
                    />
                )
            }

            {
                isModalContact && (
                    <SelectDataMaster
                        isModal={isModalContact}
                        handleClick={handleClickContact}
                        titleModal={titleModalContact}
                        setIsModal={setIsModalContact}

                        getData={getDataContact}
                        isLoading={isLoadingModalContact}
                        totalRows={totalRowsModalContact}
                        setTotalRows={setTotalRowsModalContact}
                        SelectedData={SelectedDataModalContact}
                        setSelectedData={setSelectedDataModalContact}
                        data={dataModalContact}
                        columnData={columnDataModalContact}
                        dataMap={dataMapModalContact}

                        selectHandler={selectContact}
                    />
                )
            }

            {
                isModalVehicleType && (
                    <SelectDataMaster
                        isModal={isModalVehicleType}
                        handleClick={handleClickVehicleType}
                        titleModal={titleModalVehicleType}
                        setIsModal={setIsModalVehicleType}

                        getData={getDataVehicleType}
                        isLoading={isLoadingModalVehicleType}
                        totalRows={totalRowsModalVehicleType}
                        setTotalRows={setTotalRowsModalVehicleType}
                        SelectedData={SelectedDataModalVehicleType}
                        setSelectedData={setSelectedDataModalVehicleType}
                        data={dataModalVehicleType}
                        columnData={columnDataModalVehicleType}
                        dataMap={dataMapModalVehicleType}

                        setId={props.setVehicleTypeId}
                        setName={props.setVehicleTypeName}
                    />
                )
            }

            {
                isModalShipmentOrder && (
                    <SelectDataShipmentOrder
                        isModal={isModalShipmentOrder}
                        handleClick={handleClickShipmentOrder}
                        titleModal={titleModalShipmentOrder}
                        setIsModal={setIsModalShipmentOrder}

                        getData={getDataShipmentOrder}
                        isLoading={isLoadingModalShipmentOrder}
                        totalRows={totalRowsModalShipmentOrder}
                        setTotalRows={setTotalRowsModalShipmentOrder}
                        SelectedData={SelectedDataModalShipmentOrder}
                        setSelectedData={setSelectedDataModalShipmentOrder}
                        data={dataModalShipmentOrder}
                        columnData={columnDataModalShipmentOrder}
                        dataMap={dataMapModalShipmentOrder}

                        setId={props.setShipmentOrderId}
                        setName={props.setShipmentOrderNo}
                        SelectShipmentOrder={SelectShipmentOrder}
                        setWarehouseId={props.setWarehouseId}
                        setWarehouseName={props.setWarehouseName}
                        setSiteId={props.setSiteId}
                        setSiteName={props.setSiteName}
                        jobTypeList={jobTypeList}
                        setShipperConsigneeId={props.setShipperConsigneeId}
                        setShipperConsigneeName={props.setShipperConsigneeName}
                    />
                )
            }
        </div>
    )
}

export default OutBoundHeader;