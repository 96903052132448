import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataTracking = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontstyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [selectedDetail, setSelectedDetail] = useState({});

    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setSelectedDetail({});
        setFilter([]);
        setFilterBy([]);
        setStateX({})
    };

    function Select() {
        if (props.titleModal === 'Vessel' || props.titleModal === 'Flight') {
            if (selectedDetail.sequence !== null && selectedDetail.sequence !== undefined) {
                props.setRoutingSequence(selectedDetail.sequence);

                if (props.jobType.portType === 'SEA') {
                    props.setVessel(selectedDetail.vesselName + ' V.' + selectedDetail.voyage);
                }
                else if (props.jobType.portType === 'AIR') {
                    props.setFlight(selectedDetail.flightName + ' ' + selectedDetail.flightNo);
                }
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
        }
        else if (props.titleModal === 'Container') {
            if (selectedDetail.sequence !== null && selectedDetail.sequence !== undefined) {
                props.setSeaContainerSequence(selectedDetail.sequence);
                props.setContainerNo(selectedDetail.containerNo);
                props.setSealNo(selectedDetail.sealNo);
                props.setContSizeType(selectedDetail.containerSize + '' + selectedDetail.containerTypeCode);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
        }
        else if (props.titleModal === 'Con Note') {
            if (selectedDetail.sequence !== null && selectedDetail.sequence !== undefined) {
                props.setConNoteSequence(selectedDetail.sequence);
                props.setConNote(selectedDetail.conNote);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
        }
        else {
            if (props.SelectedData.id === undefined) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
            else {
                if (props.titleModal === 'Port') {
                    props.setPortId(props.SelectedData.id);
                    props.setPortCode(props.SelectedData.code);
                    props.setPortName(props.SelectedData.name);
                }
                else if (props.titleModal === 'AirPort') {
                    if (props.jobType.portType === 'AIR') {
                        props.setAirportId(props.SelectedData.id);
                        props.setAirportCode(props.SelectedData.abbreviation);
                        props.setAirportName(props.SelectedData.name);
                    }
                    else {
                        props.setPortId(props.SelectedData.id);
                        props.setPortCode(props.SelectedData.abbreviation);
                        props.setPortName(props.SelectedData.name);
                    }
                }
            }
        }

        emptyState();

        props.setIsModal(false);
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            {
                props.titleModal === 'Vessel' || props.titleModal === 'Flight' ?
                    <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                        <div className='rounded-10 p-2 table-responsive' style={{ 'maxHeight': '500px' }}>
                            <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                                <Table sx={{ minWidth: 650 }} stickyHeader>
                                    {
                                        props.jobType.portType === 'SEA' ?
                                            <>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>No</StyledTableCell>
                                                        <StyledTableCell>Vessel</StyledTableCell>
                                                        <StyledTableCell>Type</StyledTableCell>
                                                        <StyledTableCell>Voyage</StyledTableCell>
                                                        <StyledTableCell>ETD</StyledTableCell>
                                                        <StyledTableCell>City</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        props.vesselData.length > 0 ?
                                                            props.vesselData.map((el) => {
                                                                return (
                                                                    <TableRow
                                                                        key={el.sequence}
                                                                        onClick={() => setSelectedDetail(el)}
                                                                        onDoubleClick={() => CellDoubleClick()}
                                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                    >
                                                                        <StyledTableBodyCell>{el.sequence}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell>{el.vesselName}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell>{el.vesselType}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell>{el.voyage}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell>{el.etd}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell>{el.cityName}</StyledTableBodyCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            :
                                                            <TableRow>
                                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Primary-One, #083A50)',
                                                                            fontFamily: 'Nunito',
                                                                            fontSize: '16px',
                                                                            fontstyle: 'normal',
                                                                            fontWeight: '500',
                                                                            lineHeight: 'normal'
                                                                        }}
                                                                    >
                                                                        Data Empty
                                                                    </Typography>
                                                                </StyledTableBodyCell>
                                                            </TableRow>
                                                    }
                                                </TableBody>
                                            </>
                                            :
                                            props.jobType.portType === 'AIR' ?
                                                <>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>No</StyledTableCell>
                                                            <StyledTableCell>Flight Code</StyledTableCell>
                                                            <StyledTableCell>Flight Name</StyledTableCell>
                                                            <StyledTableCell>Flight Number</StyledTableCell>
                                                            <StyledTableCell>ETD</StyledTableCell>
                                                            <StyledTableCell>City From</StyledTableCell>
                                                            <StyledTableCell>City To</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            props.flightData.length > 0 ?
                                                                props.flightData.map((el) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={el.sequence}
                                                                            onClick={() => setSelectedDetail(el)}
                                                                            onDoubleClick={() => CellDoubleClick()}
                                                                            sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                            hover={true}
                                                                        >
                                                                            <StyledTableBodyCell>{el.sequence}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.flightCode}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.flightName}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.flightNo}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.etd}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.cityFromName}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.cityToName}</StyledTableBodyCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                                :
                                                                <TableRow>
                                                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: 'var(--Primary-One, #083A50)',
                                                                                fontFamily: 'Nunito',
                                                                                fontSize: '16px',
                                                                                fontstyle: 'normal',
                                                                                fontWeight: '500',
                                                                                lineHeight: 'normal'
                                                                            }}
                                                                        >
                                                                            Data Empty
                                                                        </Typography>
                                                                    </StyledTableBodyCell>
                                                                </TableRow>
                                                        }
                                                    </TableBody>
                                                </>
                                                :
                                                <>
                                                </>
                                    }
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    :
                    props.titleModal === 'Container' ?
                        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                            <div className='rounded-10 p-2 table-responsive' style={{ 'maxHeight': '500px' }}>
                                <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                                    <Table sx={{ minWidth: 650 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Container No.</StyledTableCell>
                                                <StyledTableCell>Seal No.</StyledTableCell>
                                                <StyledTableCell>Size</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Gross Weight</StyledTableCell>
                                                <StyledTableCell>Net Weight</StyledTableCell>
                                                <StyledTableCell>CBM</StyledTableCell>
                                                <StyledTableCell>Commodity</StyledTableCell>
                                                <StyledTableCell>No of Pieces</StyledTableCell>
                                                <StyledTableCell>Packaging</StyledTableCell>
                                                <StyledTableCell>Part of</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.containerData.length > 0 ?
                                                    props.containerData.map((el) => {
                                                        return (
                                                            <TableRow
                                                                key={el.sequence}
                                                                onClick={() => setSelectedDetail(el)}
                                                                onDoubleClick={() => CellDoubleClick()}
                                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                hover={true}
                                                            >
                                                                <StyledTableBodyCell>{el.containerNo}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.sealNo}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.containerSize}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.containerTypeCode}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.grossWeight}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.netWeight}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.cbm}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.commodity}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.noOfPieces}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.packagingCode}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.partOf === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow>
                                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Primary-One, #083A50)',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '16px',
                                                                    fontstyle: 'normal',
                                                                    fontWeight: '500',
                                                                    lineHeight: 'normal'
                                                                }}
                                                            >
                                                                Data Empty
                                                            </Typography>
                                                        </StyledTableBodyCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                        :
                        props.titleModal === 'Con Note' ?
                            <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                                <div className='rounded-10 p-2 table-responsive' style={{ 'maxHeight': '500px' }}>
                                    <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Con. Note</StyledTableCell>
                                                    <StyledTableCell>Collie</StyledTableCell>
                                                    <StyledTableCell>Weight</StyledTableCell>
                                                    <StyledTableCell>Commodity</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.ConNoteData.length > 0 ?
                                                        props.ConNoteData.map((el) => {
                                                            return (
                                                                <TableRow
                                                                    key={el.sequence}
                                                                    onClick={() => setSelectedDetail(el)}
                                                                    onDoubleClick={() => CellDoubleClick()}
                                                                    sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                    hover={true}
                                                                >
                                                                    <StyledTableBodyCell>{el.conNote}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell>{el.collie}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell>{el.weight}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell>{el.commodity}</StyledTableBodyCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                        :
                                                        <TableRow>
                                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--Primary-One, #083A50)',
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: '16px',
                                                                        fontstyle: 'normal',
                                                                        fontWeight: '500',
                                                                        lineHeight: 'normal'
                                                                    }}
                                                                >
                                                                    Data Empty
                                                                </Typography>
                                                            </StyledTableBodyCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                            :
                            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                                <div>
                                    {
                                        ButtonComponent.RefreshButton(sortArr, rowsCount, props.columnData,
                                            setGoTo, handleReset, setFilterBy,
                                            filterBy, setFilter, setNumPage,
                                            setRowsCount, props.getData, stateX, setStateX
                                        )
                                    }
                                </div>

                                <div
                                    className="rounded-10 table-responsive"
                                    style={{
                                        maxHeight: '375px',
                                        marginTop: '16px',
                                        padding: '2px 16px',
                                        borderRadius: '12px',
                                        background: '#F2F2F2'
                                    }}
                                >
                                    {
                                        props.isLoading ?
                                            (
                                                <LoadingSpinner />
                                            )
                                            :
                                            (
                                                <>
                                                    <div className="mt-1">
                                                        <TableX 
                                                            uniqueKey={'id'}
                                                            goTo={goTo}
                                                            rowsCount={rowsCount}
                                                            setNumPage={setNumPage}
                                                            setRowsCount={setRowsCount}
                                                            getData={props.getData}
                                                            columnData={props.columnData}
                                                            dataMap={props.dataMap}
                                                            SelectedData={props.SelectedData}
                                                            setSelectedData={props.setSelectedData}
                                                            setFilter={setFilter}
                                                            setFilterBy={setFilterBy}
                                                            CellDoubleClick={CellDoubleClick}
                                                            stateX={stateX}
                                                            setStateX={setStateX}
                                                            sortArr={sortArr}
                                                            setSortArr={setSortArr}
                                                            setGoTo={setGoTo}
                                                        />
                                                    </div>
                                                </>
                                            )
                                    }
                                </div>

                                <div style={{ marginTop: '16px' }}>
                                    <Paginate
                                        page={numPage}
                                        limit={rowsCount}
                                        totalData={props.totalRows}
                                        onChange={onChangePaging}
                                        onChangeLimit={onChangeLimit}
                                    />
                                </div>
                            </Grid>
            }
        </>

    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataTracking;