import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import ModalFindData from './Modals/ModalFindData';
import ButtonShipment from './Buttons/ButtonShipment';
import ApiService from '../../../services/ApiService';
import TableX from '../../../components/Tables/TableX';
import freezeContainer from '../../../Styles/freezeContainer';
import ModalDelete from '../../../components/Modals/ModalDelete';
import ModalUsageData from '../../../components/Modals/ModalUsageData';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { newWindowConfig } from '../../../utils/masks.jsx';
import SelectDataBooking from './Selects/SelectDataBooking.jsx';

const ListShipment = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate();
  const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));

  const [dataJobType, setDataJobType] = useState([]);
  const [jobTypeData, setJobTypeData] = useState({});

  const [isModal, setIsModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [ContainerNo, setContainerNo] = useState('');
  const [FeederVessel, setFeederVessel] = useState('');
  const [RefShipper, setRefShipper] = useState('');
  const [MBL, setMBL] = useState('');
  const [MAWB, setMAWB] = useState('');
  const [HBL, setHBL] = useState('');
  const [HAWB, setHAWB] = useState('');
  const [isRange, setIsRange] = useState(false);
  const [ETFrom, setETFrom] = useState('');
  const [ETTo, setETTo] = useState('');
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState('');

  const [isModalUsage, setIsModalUsage] = useState(false);
  const [titleModalUsage, setTitleModalUsage] = useState('');
  const [usageDataList, setUsageDataList] = useState([]);

  const [DropDownTitle, setDropDownTitle] = useState('All');

  const [titleModalBC, setTitleModalBC] = useState('');
  const [isModalBC, setIsModalBC] = useState(false);
  const paramB = 'bookingConfirmation/bookingConfirmation';
  const [isLoadingB, setIsLoadingB] = useState(false);
  const [dataB, setDataB] = useState([]);
  const [dataMapB, setDataMapB] = useState([]);
  const [columnDataB, setColumnDataB] = useState([]);
  const [SelectedDataB, setSelectedDataB] = useState({});
  const [totalRowsB, setTotalRowsB] = useState(50);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Shipment Order', link: '/Majura/ShipmentOrder', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    document.title = 'Shipment Order - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters, orderBy, data) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }
    let filter = [];
    let params = [];
    params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];

    if (data) {
      if (data === 'All') {
        filter = [...filters]
      }
      else if (data === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (data === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
      }
    }
    else {
      if (DropDownTitle === 'All') {
        filter = [...filters]
      }
      else if (DropDownTitle === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (DropDownTitle === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
      }
    }

    ApiService.ListOperationDynamicPostByPage(paramShipmentOrder, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.shipmentOrder
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (pageNumber === 0) {
            setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
          }

          if (isLoadingBackDrop === false) {
            setIsLoading(false)
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          if (isLoadingBackDrop === false) {
            setIsLoading(false)
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false)
        }
        else {
          setIsLoadingBackDrop(false);
        }
      })
      .catch(function (error) {

        if (isLoadingBackDrop === false) {
          setIsLoading(false)
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const getData2 = (pageNumber, pageSize, filters, jobType) => {
    setIsLoading(true);
    let params = [];
    let filter;
    params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];


    if (DropDownTitle === 'All') {
      filter = [...filters]
    }
    else if (DropDownTitle === 'Deleted') {
      filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
    }
    else if (DropDownTitle === 'Active') {
      filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
    }

    ApiService.ListOperationDynamicPostByPage(paramShipmentOrder, 'PAGE', pageNumber, pageSize, params, filter)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.shipmentOrder
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (pageNumber === 0) {
            setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
          }

          setIsLoading(false)
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          setIsLoading(false)
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false);

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const GetDataJobType = () => {
    ApiService.PostByPage('regJobType/regJobType', 1, 50)
      .then((response) => {
        if (response.data.code === 200) {
          setDataJobType(response.data.data.jobType);

          getData(0, 50, []);
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          if (isLoadingBackDrop === false) {
            setIsLoading(false)
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false)
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const getDataAccess = () => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            if (hasAccess) {
              GetDataJobType();
            }
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
            if (isLoadingBackDrop === false) {
              setIsLoading(false)
            }
            else {
              setIsLoadingBackDrop(false);
            }
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          history('/Majura');
        })
    }
    else {
      history('/Majura');
    }
  }

  const handleChange = (e) => {
    localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
    setJobType(e.target.value);
    emptyState();
    getData2(0, 50, filter, e.target.value);
  };

  const handleReset = () => {
    emptyState();
    getData(0, 50, []);
  };

  const AddSh = () => {
    if (jobType !== '10') {
      setIsLoadingBackDrop(true);
      handleClickBC();
      setTitleModalBC('Select Booking Confirmation');
      // window.open('/Majura/ShipmentOrder/Create/' + jobType, newWindowConfig);
    }
    else {
      showToast({ type: 'info', message: 'Coming Soon!' });
    }
  }

  const EditSh = () => {
    let check = 0;
    DataRegUserAccess.forEach(element => {
      if (element.accessCode === 'REI') {
        check = 1;
      }
    });

    if (check !== 0) {
      if (!SelectedData?.id) {
        showToast({ type: 'error', message: "Please Select Data!" });
      }
      else {
        if (jobType !== '10') {
          window.open('/Majura/ShipmentOrder/' + jobType + '/' + SelectedData.id, newWindowConfig);
        }
        else {
          showToast({ type: 'info', message: 'Coming Soon!' });
        }
      }
    }
    else {
      showToast({ type: 'error', message: 'You Don\'t Have Access!' });
    }
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data!' });
    emptyState();
    getData(0, 50, []);
  }

  const DeleteData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: "Please Select Data!" });
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        if (SelectedData.eplId === 0) {
          if (SelectedData.approved) {
            showToast({ type: 'error', message: 'Can not delete data, already approved!' });
          }
          else {
            setTitleModalDelete('Shipment Order');
            handleClick2();
          }
        }
        else {
          setIsLoadingBackDrop(true);
          getDataEPL(SelectedData.eplId);
        }
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted!' });
      }
    }
  }

  const getDataEPL = (eplId) => {
    let sequence = 1;
    let tempArray = [];

    ApiService.OperationPostById('estimateProfitLoss/estimateProfitLoss', eplId)
      .then((response) => {
        if (response.data.code === 200) {
          let temp = response.data.data.estimateProfitLoss;

          tempArray = [
            {
              'sequence': sequence,
              'usedOn': 'Estimate Profit Loss',
              'transactionNumber': temp.eplNo
            }
          ];

          setUsageDataList(tempArray);
          setTitleModalUsage('Shipment Order');
          handleClickUsage();

          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Shipment Order Already Used in Estimate Profit Loss!' });
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsLoadingBackDrop(false);

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const DeleteShipment = () => {
    ApiService.OperationDelete(paramShipmentOrder, SelectedData.id).then((res) => {
      if (res.data.code === 200) {
        getData(numPage, rowsCount, filter);
        showToast({ type: 'success', message: 'Delete Data Success!' });
        setIsModalDelete(false);
      }
      else {
        showToast({ type: 'error', message: res.data.message || 'Delete Data Failed!' });
        setIsModalDelete(false);
      }
    });
  }

  const FindData = () => {
    setTitleModal('Find Shipment Order');
    dataJobType.forEach((el) => {
      if (el.id === jobType) {
        setJobTypeData(el);
      }
    });
    handleClick();
  };

  const emptyState = () => {
    setNumPage(0);
    setRowsCount(50);
    setTotalRows(50);
    setGoTo(1);
    setFilter([]);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
  };

  function CellDoubleClick(row) {
    EditSh();
  };

  const handleClick = () => {
    if (isModal === false) {
      setIsModal(true);
    }
    else {
      setIsModal(false);
    }
  };

  const handleClick2 = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
    }
    else {
      setIsModalDelete(false);
    }
  }

  const handleClickUsage = () => {
    if (isModalUsage === false) {
      setIsModalUsage(true);
    }
    else {
      setIsModalUsage(false);
    }
  }

  function HandleStatus(data) {
    setDropDownTitle(data);
    getData(0, 50, filter, sortArr, data);
  }

  const handleClickBC = () => {
    if (isModalBC === false) {
      setIsModalBC(true);
      getDataBooking(1, 50);
    }
    else {
      setIsModalBC(false);
    }
  };

  const getDataBooking = (pageNumber, pageSize, filters, orderBy) => {
    setIsLoadingB(true);
    let params = [];
    params = [
      ...params,
      { 'attr': 'jobTypeId', 'value': '' + jobType },
      { 'attr': 'approved', 'value': 'true' },
      { 'attr': 'shipmentId', 'value': '0' },
      { 'attr': 'rowStatus', 'value': 'ACT' },
    ];
    ApiService.OperationDynamicPostByPage(paramB, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataB({});
          setDataB(response.data.data.bookingConfirmation);

          let temp = response.data.data.bookingConfirmation;
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp;
          })

          setDataMapB(indexed);
          setColumnDataB(response.data.data.columns.headerColumns);
          setTotalRowsB(response.data.totalRowCount);

          setIsLoadingB(false);
        }
        setIsLoadingB(false);
        setIsLoadingBackDrop(false);
      })
      .catch(function (error) {
        setIsLoadingB(false);
        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
      })
  };

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(0);
    setRowsCount(value);
    getData(0, value, filter);
  }

  const ButtonComponents = () => {
    return (
      <ButtonShipment
        AddSh={AddSh}
        EditSh={EditSh}
        ReloadData={ReloadData}
        DeleteData={DeleteData}
        FindData={FindData}
        DataRegUserAccess={DataRegUserAccess}
        DropDownTitle={DropDownTitle}
        HandleStatus={HandleStatus}
      />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          btnPageRef={btnPageRef}
          title={'Shipment Order'}
          isJobType={true}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
          jobTypeId={jobType}
          handleSelect={handleChange}
          dataJobType={dataJobType}
        />

        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#F2F2F2'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <div className='mt-1'>
                    <TableX
                      uniqueKey={'id'}
                      goTo={goTo}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={getData}
                      columnData={columnData}
                      dataMap={dataMap}
                      SelectedData={SelectedData}
                      setSelectedData={setSelectedData}
                      setFilter={setFilter}
                      setFilterBy={setFilterBy}
                      CellDoubleClick={CellDoubleClick}
                      stateX={stateX}
                      setStateX={setStateX}
                      sortArr={sortArr}
                      setSortArr={setSortArr}
                      setGoTo={setGoTo}
                    />
                  </div>
                </>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModal && (
          <ModalFindData
            isModal={isModal}
            handleClick={handleClick}
            titleModal={titleModal}
            setIsModal={setIsModal}
            jobTypeData={jobTypeData}
            jobType={jobType}
            DataRegUserAccess={DataRegUserAccess}

            ContainerNo={ContainerNo}
            setContainerNo={setContainerNo}
            FeederVessel={FeederVessel}
            setFeederVessel={setFeederVessel}
            RefShipper={RefShipper}
            setRefShipper={setRefShipper}
            MBL={MBL}
            setMBL={setMBL}
            MAWB={MAWB}
            setMAWB={setMAWB}
            HBL={HBL}
            setHBL={setHBL}
            HAWB={HAWB}
            setHAWB={setHAWB}
            isRange={isRange}
            setIsRange={setIsRange}
            ETFrom={ETFrom}
            setETFrom={setETFrom}
            ETTo={ETTo}
            setETTo={setETTo}
          />
        )
      }

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClick2}
            DeleteData={DeleteShipment}
          />
        )
      }

      {
        isModalUsage && (
          <ModalUsageData
            isModal={isModalUsage}
            titleModal={titleModalUsage}
            handleClick={handleClickUsage}
            usageDataList={usageDataList}
          />
        )
      }

      {
        isModalBC && (
          <SelectDataBooking
            isModal={isModalBC}
            handleClick={handleClickBC}
            titleModal={titleModalBC}
            setIsModal={setIsModalBC}
            jobType={jobType}

            getData={getDataBooking}
            isLoading={isLoadingB}
            setIsLoading={setIsLoadingB}
            data={dataB}
            setData={setDataB}
            dataMap={dataMapB}
            setDataMap={setDataMapB}
            columnData={columnDataB}
            setColumnData={setColumnDataB}
            SelectedData={SelectedDataB}
            setSelectedData={setSelectedDataB}
            totalRows={totalRowsB}
            setTotalRows={setTotalRowsB}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default ListShipment